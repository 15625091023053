import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import "./App.css";
import { envTypeGet } from "../common/common";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "components/layout/ErrorFallback";
import WsnMainContent from "components/layout/WsnMainContent";

console.log("envTypeGet: ", envTypeGet());

console.log("env", process.env.NODE_ENV);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => {
          // reset the state of your app here
        }}
        resetKeys={["someKey"]}
      >
        <WsnMainContent />
      </ErrorBoundary>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
