import { useContext, useEffect } from "react";
import { wsInit } from "common/ws/ws_notify";
import { messageReceiveHandle } from "common/ws/ws_message";
import { WsNotifyContext } from "common/ws/WsNotify";
import WsnTestContent from "./WsnTestContent";
import WsnConnectionMessage from "./WsnConnectionMessage";

const WsnStart = () => {
  const wsNotifyContextValue = useContext(WsNotifyContext);
  useEffect(() => {
    wsInit(messageReceiveHandle, {
      notifyTime: wsNotifyContextValue.notifyTime,
      setNotifyTime: wsNotifyContextValue.setNotifyTime,
      setConnectionInfo: wsNotifyContextValue.setConnectionInfo,
    });
  }, []);

  return (
    <div>
      <div>START</div>
      <div>
        wsNotifyContextValue:
        {wsNotifyContextValue.notifyTime
          ? wsNotifyContextValue.notifyTime.toLocaleString()
          : ""}
      </div>
      <WsnTestContent />
      <WsnConnectionMessage />
    </div>
  );
};

export default WsnStart;
