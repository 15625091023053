import { useContext } from "react";
import { WsNotifyContext } from "common/ws/WsNotify";
import styled from "styled-components";

const WsnConnectionMessage = () => {
  const { connectionInfo } = useContext(WsNotifyContext);

  return <>{!connectionInfo.ok && <Div>{connectionInfo.message}</Div>}</>;
};

const Div = styled.div`
  margin-top: 1rem;
  font-size: 1.1rem;
  font-weight: bold;
`;

export default WsnConnectionMessage;
