import { AxiosInstance } from "axios";
import { apiTypeUrlBaseGet } from "../api_common";
import { API_TYPES } from "../api_constants";
import { axiosInstanceCreate } from "./axios_common";

const wsnUrlBase: string = apiTypeUrlBaseGet(API_TYPES.WSN);

const API_WSN_RETRY_LIMIT: number = 3;

export const axiosWsn: AxiosInstance = axiosInstanceCreate(wsnUrlBase);

axiosWsn.interceptors.response.use(null, async (err) => {
  if (typeof err.config.retryLimit === "undefined") {
    err.config.retryLimit = API_WSN_RETRY_LIMIT;
  }
  if (err.config.retryLimit === 0) {
    throw err;
  }
  err.config.retryLimit--;
  const response = await axiosWsn(err.config);
  return response;
});
