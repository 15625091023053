import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { envTypeGet } from "common/common";
import WsnStart from "components/pages/WsnStart/WsnStart";
import MainContentView from "./MainContentView";

console.log("envTypeGet: ", envTypeGet(), "apiUrlBaseGet: ");

export default function WsnMainContent() {
  return (
    <Router>
      <Routes>
        <Route element={<MainContentView />}>
          <Route path="/" element={<WsnStart />} />
        </Route>
      </Routes>
    </Router>
  );
}
