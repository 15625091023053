import { envTypeGet } from "../common";
import { API_TYPES } from "./api_constants";

export type UrlBases = {
  wsn: string;
};

export type ApiType = "wsn";

export const apiUrlBasesGet = (): UrlBases => {
  const envType = envTypeGet();
  let urlBases = {
    wsn: "http://localhost:3001/api/v1",
  };
  if (envType === "production") {
    urlBases = {
      wsn: "https://wsn.tehtest.emlearn.info/api/wsn/v1",
    };
  }

  return urlBases;
};

export const apiTypeUrlBaseGet = (apiType: string): string => {
  const urlBases: UrlBases = apiUrlBasesGet();
  const url = urlBases[apiType as ApiType];
  return url;
};

export const apiTypeUrlGet = (apiType: string, path: string): string => {
  const urlBase: string = apiTypeUrlBaseGet(apiType);
  const url = urlBase + "/" + path;
  return url;
};

export const apiWsnUrlGet = (path: string = ""): string => {
  const url: string = apiTypeUrlGet(API_TYPES.WSN, path);
  return url;
};
