import WsNotify from "common/ws/WsNotify";
import React from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";

export default function MainContentView() {
  return (
    <WsNotify>
      <Div>
        <Outlet />
      </Div>
    </WsNotify>
  );
}

const Div = styled.div`
  display: flex;
  margin-top: 4rem;
  margin-left: 2rem;
  margin-right: 2rem;
  flex-direction: column;
  // color: #764141;
`;
