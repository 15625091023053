const URL_NAME_SUBSTRING = "wsn.tehtest";

export function obDefaultValGet(ob, key, defaultVal = "") {
  let val = defaultVal;
  if (ob !== null) {
    val = ob.hasOwnProperty(key) ? ob[key] : defaultVal;
  }
  return val;
}

export const copyToClipboard = (str) => {
  const el = document.createElement("textarea"); // Create a <textarea> element
  el.value = str; // Set its value to the string that you want copied
  el.setAttribute("readonly", ""); // Make it readonly to be tamper-proof
  el.style.position = "absolute";
  el.style.left = "-9999px"; // Move outside the screen to make it invisible
  document.body.appendChild(el); // Append the <textarea> element to the HTML document
  const selected =
    document.getSelection().rangeCount > 0 // Check if there is any content selected previously
      ? document.getSelection().getRangeAt(0) // Store selection if found
      : false; // Mark as false to know no selection existed before
  el.select(); // Select the <textarea> content
  document.execCommand("copy"); // Copy - only works as a result of a user action (e.g. click events)
  document.body.removeChild(el); // Remove the <textarea> element
  if (selected) {
    // If a selection existed before copying
    document.getSelection().removeAllRanges(); // Unselect everything on the HTML document
    document.getSelection().addRange(selected); // Restore the original selection
  }
};

export const envTypeDomainGet = (name) => {
  let envType = "";
  if (
    typeof process !== "undefined" &&
    typeof process.env !== "undefined" &&
    typeof process.env.NODE_ENV !== "undefined" &&
    process.env.NODE_ENV === "test"
  ) {
    envType = "test";
  } else {
    const loc = window.location.href + "";
    if (loc.includes("localhost")) {
      envType = "dev";
    } else if (loc.includes("t." + name)) {
      envType = "local_address";
    } else if (loc.includes(name)) {
      envType = "production";
    }
  }
  return envType;
};

export const envTypeGet = () => {
  return envTypeDomainGet(URL_NAME_SUBSTRING);
};

export const envIsProduction = () => {
  const envType = envTypeGet();
  return envType === "production";
};
